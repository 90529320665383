cf-youtube-video {
  display: block;
  overflow: hidden;
  position: relative;
}
cf-youtube-video[horizontal-responsive] {
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
}
cf-youtube-video[vertical-responsive-global] {
  height: calc(100vh - 208px);
  width: calc((100vh - 208px) * 1.7788461538461537);
}
cf-youtube-video[vertical-responsive-global].max-width-1110 {
  max-width: 1110px;
  max-height: 624px;
}
cf-youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
