cf-auto-complete {
  display: block;
  cursor: pointer;
}
cf-auto-complete .chip-menu-item_positioning {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
cf-auto-complete .chip-menu-item_positioning *:not(:first-child) {
  margin-left: 6px;
}
cf-auto-complete .chip-menu-item_paddings {
  padding: 4px 24px 4px 12px;
}
cf-auto-complete .ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
}
cf-auto-complete .menu_no-padding {
  padding: 0;
}
cf-auto-complete .menu_outline {
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4), 0 0 0 1px rgba(32, 32, 32, 0.08), 0 1px 0 0 rgba(32, 32, 32, 0.16);
}
