cf-cols {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 996px;
}
cf-cols .col-size {
  height: 100%;
  min-width: 0;
  flex-basis: calc(33.3333% +  100px / 3);
}
cf-cols cf-col-1 {
  margin-left: -100px;
  padding-left: 100px;
  height: 100%;
  min-width: 0;
  flex-basis: calc(33.3333% +  100px / 3);
}
cf-cols cf-col-2 {
  min-width: 378px;
  max-width: 538px;
  height: 100%;
  min-width: 0;
  flex-basis: calc(33.3333% +  100px / 3);
}
cf-cols cf-col-3 {
  height: 100%;
  min-width: 0;
  flex-basis: calc(33.3333% +  100px / 3);
}
