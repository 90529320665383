/*112px - left/right padding (112*2 = 224px)*/
@import '../../modern-ui/_common/colors/colors.css';
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
.text-ellipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-normal {
  text-transform: none;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-nowrap {
  white-space: nowrap;
}
.text-undeline {
  text-decoration: underline;
}
.popup .text-info {
  color: var(--greyDark);
  font-size: 12px;
}
.text-through {
  text-decoration: line-through;
}
.display-1 {
  text-align: left;
  height: 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  color: var(--black);
}
.body-25 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: var(--black);
}
.body-24 {
  font-size: 18px;
  font-weight: bold;
  color: var(--white);
  line-height: 20px;
}
.body-23 {
  font-size: 12px;
  color: #a8d3f8;
}
.body-22 {
  font-size: 16px;
  line-height: 1.2;
}
.body-21 {
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
  text-align: center;
}
.body-20 {
  font-size: 31px;
  font-weight: bold;
  color: var(--black);
}
.body-19 {
  font-size: 14px;
  line-height: 1.38;
  color: var(--black);
}
.body-18 {
  font-size: 14px;
  line-height: 1.23;
}
.body-17 {
  font-size: 16px;
  text-align: left;
  color: var(--black);
}
.body-16 {
  font-size: 13px;
  color: var(--grey);
}
.body-15 {
  font-size: 18px;
  font-weight: bold;
  color: var(--black);
}
.body-14 {
  opacity: 0.48;
  font-size: 12px;
  text-align: left;
  color: #282828;
}
.heading {
  font-size: 24px;
  line-height: 32px;
  color: var(--greyDark);
  margin-bottom: calc(var(--unit) * 4);
}
.paragraph {
  margin: 4px 0;
}
.body-13 {
  font-size: 23px;
  color: --greyDark;
}
.body-12 {
  font-size: 15px;
  color: var(--grey);
}
.body-11 {
  font-size: 23px;
  font-weight: bold;
  line-height: 1.04;
  color: var(--black);
}
.body-10 {
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: --greyDark;
}
.body-9 {
  font-size: 23px;
  line-height: 1.39;
  color: var(--white);
  letter-spacing: -0.4px;
}
.body-8 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--greyLight30);
  letter-spacing: -0.4px;
}
.body-7 {
  font-size: 15px;
  line-height: 1.07;
  text-align: left;
  color: var(--white);
}
.body-6 {
  font-size: 14px;
  text-align: left;
  color: --greyDark;
}
.body-5 {
  font-size: 14px;
  text-align: left;
  color: var(--black);
}
.body-4 {
  font-size: 16px;
  text-align: left;
  color: #616161;
}
.body-2 {
  font-size: 14px;
  text-align: left;
  color: #40403c;
}
.body-1 {
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: #666666;
}
.body-3 {
  font-size: 15px;
  line-height: 20px;
  color: var(--black);
}
.body-3-semibold {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: var(--black);
}
.blue-text {
  color: var(--blue) !important;
}
.underline-on-hover {
  text-decoration: none;
  cursor: pointer;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.no-underline {
  text-decoration: none;
  cursor: pointer;
}
.underline-no-on-hover {
  text-decoration: underline;
  cursor: pointer;
}
.underline-no-on-hover:hover {
  text-decoration: none;
}
.link {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.link-2 {
  color: #0084ff;
  text-decoration: none;
  cursor: pointer;
}
.link-2:hover {
  text-decoration: underline;
}
.link-3 {
  color: var(--grey);
  text-decoration: none;
  cursor: pointer;
}
.link-3:hover {
  text-decoration: underline;
}
.link-3 {
  color: var(--grey);
  text-decoration: none;
  cursor: pointer;
}
.link-3:hover {
  text-decoration: underline;
}
.link-4 {
  color: --greyDark;
  text-decoration: underline;
  cursor: pointer;
}
.link-4:hover {
  text-decoration: none;
}
.link-5 {
  color: var(--red);
  text-decoration: none;
  cursor: pointer;
}
.link-5:hover {
  text-decoration: underline;
}
.link-6 {
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
}
.link-6:hover {
  text-decoration: none;
}
.icon-link {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
  transition: color 240ms ease 0ms;
}
.icon-link icon {
  opacity: 0.87;
  transition: opacity 240ms ease 0ms;
}
.icon-link:hover {
  color: var(--blueDark10);
}
.icon-link:hover icon {
  opacity: 1;
}
.link-main-color-no-underline {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
}
.full-opacity-on-hover {
  opacity: 0.6;
  cursor: pointer;
}
.full-opacity-on-hover:hover {
  opacity: 1;
}
.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
.disabled * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.disabled .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
chip-input {
  display: block;
  position: relative;
  top: -1px;
}
chip-input[ellipsis='true'] .text-filed_layout {
  width: 100%;
}
chip-input .counter_shape {
  border-radius: 4px;
  background-color: var(--blue);
  font-size: 11px;
  color: var(--white);
  padding: 4px;
  position: absolute;
  right: 4px;
}
chip-input .input_container {
  position: relative;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-items: left;
  min-height: 28px;
  overflow: hidden;
  top: -1px;
}
chip-input .no-wrap {
  white-space: nowrap;
}
chip-input .text-field-box_paddings {
  padding-left: 10px;
}
chip-input .text-field-box_shape {
  flex-wrap: wrap !important;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
chip-input .remove-left-border {
  border-left: 0;
}
chip-input .commaa-margin {
  margin-right: 4px;
  position: relative;
  top: -2px;
}
chip-input[disabled] {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
chip-input[disabled] * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
chip-input[disabled] .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
highliter {
  display: inline;
  border-radius: 2px;
  position: relative;
  vertical-align: 1px;
  font-size: 15px;
  line-height: 20px;
  color: var(--black);
}
highliter[type='main-normal'] {
  background-color: var(--white);
  color: var(--blue);
  border-color: var(--white);
}
highliter[hover-type='main-normal']:hover {
  background-color: var(--white);
  color: var(--blue);
  border-color: var(--white);
}
highliter[type='main-selected'] {
  background-color: var(--blue);
  color: var(--white);
  border-color: var(--blue);
}
highliter[hover-type='main-selected']:hover {
  background-color: var(--blue);
  color: var(--white);
  border-color: var(--blue);
}
.flex-none {
  flex: none;
}
.transparent-placeholder:before {
  color: transparent !important;
}
.items-box_height {
  height: auto !important;
  top: 1px !important;
}
.items-box_block {
  display: block;
}
.items-no-wrap {
  flex-wrap: nowrap !important;
  overflow: inherit;
}
.multi-items-input_position {
  margin: -1px 0 1px;
}
