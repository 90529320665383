/*112px - left/right padding (112*2 = 224px)*/
sub-header {
  display: inline-block;
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--grey);
  text-transform: lowercase;
}
sub-header.big {
  font-size: 16px;
  color: var(--greyDark);
}
