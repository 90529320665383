/*112px - left/right padding (112*2 = 224px)*/
modal-popup {
  display: block;
  position: relative;
  border-radius: 16px !important;
  padding: 24px 32px 84px !important;
  width: 632px !important;
  min-height: 400px;
  overflow: visible !important;
}
modal-popup .modal-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  text-align: left;
  color: var(--black);
  margin: 0 0 16px;
}
modal-popup .modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 84px;
  display: flex;
  align-items: center;
  padding: 0 32px;
}
modal-popup .modal-footer > * {
  margin: 0 8px 0 0;
}
