checkbox {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
checkbox[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
checkbox .checkbox-input {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  z-index: 10;
  opacity: 0;
}
checkbox .checkbox-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  z-index: 1;
  background: url('../images/ic_checkbox.svg') center center no-repeat;
}
checkbox .checkbox-input:checked + .checkbox-mask {
  background-image: url('../images/ic_checkbox_checked.svg');
}
