cf-popover {
  position: fixed;
  opacity: 0;
  display: none;
  top: 0;
  left: 0;
  z-index: var(--zindex-over-content);
  white-space: nowrap;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
cf-popover[animate] {
  transition: opacity 200ms;
}
cf-popover[cf-open='true'] {
  display: flex;
  opacity: 1;
}
cf-popover cf-popover-content {
  display: block;
  width: 100%;
}
