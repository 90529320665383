/* TODO move to mixin if good idea*/
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
cf-popover-like {
  position: absolute;
  opacity: 0;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  white-space: nowrap;
}
cf-popover-like[animate] {
  transition: opacity 240ms ease 0ms;
}
cf-popover-like[cf-open='true'] {
  display: flex;
  opacity: 1;
}
cf-popover-like[position='right'] {
  left: auto;
  right: 0;
}
cf-popover-like cf-popover-content {
  display: block;
  width: 100%;
}
