.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
.disabled * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.disabled .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
drop-down-menu {
  display: inline-block;
  cursor: pointer;
}
drop-down-menu * {
  /* override user-select rule in text-field*/
  -webkit-user-select: none !important;
          user-select: none !important;
}
drop-down-menu[show-arrow='true'] {
  position: relative;
}
drop-down-menu[show-arrow='true']::after {
  content: '';
  position: absolute;
  right: 0;
  top: 16px;
  z-index: var(--zindex-over-content);
  pointer-events: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 3.5px 0 3.5px;
  border-color: var(--greyDark) transparent transparent transparent;
}
drop-down-menu[disabled='disabled'] {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
drop-down-menu[disabled='disabled'] * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
drop-down-menu[disabled='disabled'] .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
