.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
ui-notification ui-notification-body {
  transition: 200ms cubic-bezier(0.1, 0.5, 0.5, 1) transform;
  z-index: var(--zindex-modal-background);
  position: fixed;
  left: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  opacity: 0.96;
  border-radius: 8px;
  background-color: var(--greyDark);
}
ui-notification ui-notification-body.ng-enter,
ui-notification ui-notification-body.ng-leave.ng-leave-active {
  transform: translateX(calc(-100% - 16px));
}
ui-notification ui-notification-body.fade.ng-enter.ng-enter-active,
ui-notification ui-notification-body.ng-leave {
  transform: translateX(0);
}
ui-notification ui-notification-body .text {
  font-size: 14px;
  color: var(--white);
  margin: 0 16px;
}
ui-notification ui-notification-body cf-button {
  background-color: var(--greyDark);
  margin-right: 6px;
  border: solid 1px rgba(255, 255, 255, 0.24);
}
ui-notification ui-notification-body cf-button-label {
  background-color: var(--greyDark);
  color: var(--white);
}
