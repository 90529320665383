@import '../../modern-ui/_common/colors/colors.css';
body.no-scroll-modal-open {
  overflow: hidden;
}
modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  z-index: var(--zindex-modal-background);
  align-items: flex-start;
  justify-content: center;
  padding: 100px 0;
}
modal .button_close_positioning {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.6;
}
modal .button_close_positioning:hover {
  opacity: 1;
}
modal > .modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(37, 47, 51, 0.8);
  z-index: var(--zindex-modal-background);
}
modal modal-container {
  max-width: 600px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  z-index: var(--zindex-modal);
  position: relative;
  background-color: var(--white);
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.16);
}
modal modal-container modal-content {
  flex: 1 1 auto;
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}
modal modal-container modal-content.with-actions {
  margin-bottom: 84px;
}
modal modal-container modal-content .popup-header {
  margin: 4px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  text-align: left;
  color: var(--black);
}
modal modal-container modal-content .body-1_spacing {
  margin: 8px 0 4px;
}
modal modal-container modal-content .inputs-container_spacing {
  margin-top: 12px;
}
modal modal-container modal-content .container-positioning {
  padding: 20px 32px 32px 32px;
}
modal modal-container modalactions {
  width: 100%;
}
modal modal-container internal-modal-actions {
  position: absolute;
  bottom: 0;
  flex: 0 0 auto;
  background-color: rgba(255, 255, 255, 0.96);
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  border-radius: 12px;
}
modal[theme='gray'] modal-container {
  background-color: var(--greyLight30);
}
modal[theme='gray'] internal-modal-actions {
  background-color: rgba(255, 255, 255, 0);
}
modal[theme='blue'] modal-container {
  background-color: var(--blue);
}
modal[theme='blue'] internal-modal-actions {
  background-color: rgba(255, 255, 255, 0);
}
modal[theme='transparent'] modal-container {
  background-color: transparent;
}
modal[theme='transparent'] internal-modal-actions {
  background-color: transparent;
}
modal[scrollable='true'] {
  overflow-y: auto;
  padding: 10px 0;
  align-items: center;
}
modal[scrollable='true'] modal-container {
  max-height: unset;
  margin: auto;
}
