dum-container {
  display: flex;
  flex: 1 1 auto;
  padding: 32px;
  background-color: var(--white);
  justify-content: center;
}
dum-container dum-container-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 680px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.16);
}
