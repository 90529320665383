fb-button {
  display: block;
  height: 32px;
}
fb-button .fb-plugin-box {
  height: 100%;
  width: 127px;
  overflow: hidden;
  background-color: #0084ff;
  border-radius: 7px;
  position: relative;
}
fb-button .fb-plugin-box::before {
  content: attr(button-text);
  position: absolute;
  z-index: var(--zindex-over-content);
  right: 0;
  top: 0;
  pointer-events: none;
  width: 97px;
  height: 100%;
  line-height: 32px;
  font-size: 14px;
  text-align: center;
  color: var(--white);
  background-color: #0084ff;
  padding: 0 14px 0 0;
}
fb-button .fb-plugin-box.loading {
  background: url('../images/spinner_w.svg') no-repeat 10px center #0084ff;
  background-size: 18px 18px;
}
fb-button .fb-plugin-box .fb-send-to-messenger {
  margin: -4px 0 0 -1px;
}
fb-button .fb-plugin-box .fb-send-to-messenger iframe {
  position: static !important;
}
fb-button .view-on-messenger {
  width: 127px;
  height: 100%;
  padding: 0 0 0 33px;
  border-radius: 5px;
  background: url('../images/ic_external_blue.svg') no-repeat 8px center #fbfafa;
  border: solid 1px #1885ff;
  cursor: pointer;
  transition: width 250ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
fb-button .view-on-messenger .view-on-messenger-label {
  pointer-events: none;
  font-size: 14px;
  text-align: left;
  color: #1885ff;
  line-height: 30px;
  opacity: 0;
  transition: opacity 100ms 150ms;
}
fb-button .view-on-messenger.show {
  width: 201px;
}
fb-button .view-on-messenger.show .view-on-messenger-label {
  opacity: 1;
}
fb-button[size='big'] {
  height: 36px;
}
fb-button[size='big'] .fb-plugin-box::before {
  line-height: 36px;
}
fb-button[size='big'] .fb-send-to-messenger {
  margin: -2px 0 0 -1px;
}
fb-button[size='big'] .view-on-messenger .view-on-messenger-label {
  line-height: 34px;
}
