@import '../../modern-ui/_common/colors/colors.css';
button.chip,
a.chip {
  display: inline-flex;
  height: 28px;
  border-radius: 12px;
  border-style: solid;
  text-decoration: none;
  border-top-width: 3px;
  border-bottom-width: 3px;
  border-left-width: 12px;
  border-right-width: 12px;
  border-color: transparent;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
button.chip[narrow],
a.chip[narrow] {
  border-left-width: 8px;
  border-right-width: 8px;
  border-radius: 8px;
}
button.chip[size='large'],
a.chip[size='large'] {
  border-left-width: 12px;
  border-right-width: 12px;
  border-top-width: 8px;
  border-bottom-width: 8px;
  border-radius: 18px;
}
button.chip[ellipsis='true'],
a.chip[ellipsis='true'] {
  display: inline-block;
  text-overflow: ellipsis;
}
button.chip[multiline='true'],
a.chip[multiline='true'] {
  white-space: normal;
  flex-wrap: inherit;
  height: auto;
  display: block;
  width: 100%;
}
button.chip[type='brown'],
a.chip[type='brown'] {
  background-color: #906d60;
  color: var(--white);
  border-color: #906d60;
}
button.chip[hover-type='brown']:hover,
a.chip[hover-type='brown']:hover {
  background-color: #906d60;
  color: var(--white);
  border-color: #906d60;
}
button.chip[type='yellow'],
a.chip[type='yellow'] {
  background-color: #f4ae63;
  color: var(--white);
  border-color: #f4ae63;
}
button.chip[hover-type='yellow']:hover,
a.chip[hover-type='yellow']:hover {
  background-color: #f4ae63;
  color: var(--white);
  border-color: #f4ae63;
}
button.chip[type='light-gray'],
a.chip[type='light-gray'] {
  background-color: var(--greyLight20);
  color: black;
  border-color: var(--greyLight20);
}
button.chip[hover-type='light-gray']:hover,
a.chip[hover-type='light-gray']:hover {
  background-color: var(--greyLight20);
  color: black;
  border-color: var(--greyLight20);
}
button.chip[type='gray'],
a.chip[type='gray'] {
  background-color: #e6e6e6;
  color: #40403c;
  border-color: #e6e6e6;
}
button.chip[hover-type='gray']:hover,
a.chip[hover-type='gray']:hover {
  background-color: #e6e6e6;
  color: #40403c;
  border-color: #e6e6e6;
}
button.chip[type='transparent'],
a.chip[type='transparent'] {
  background-color: rgba(0, 0, 0, 0);
  color: var(--black);
  border-color: rgba(0, 0, 0, 0);
}
button.chip[hover-type='transparent']:hover,
a.chip[hover-type='transparent']:hover {
  background-color: rgba(0, 0, 0, 0);
  color: var(--black);
  border-color: rgba(0, 0, 0, 0);
}
button.chip[type='transparent-white'],
a.chip[type='transparent-white'] {
  background-color: rgba(0, 0, 0, 0);
  color: var(--white);
  border-color: rgba(0, 0, 0, 0);
}
button.chip[hover-type='transparent-white']:hover,
a.chip[hover-type='transparent-white']:hover {
  background-color: rgba(0, 0, 0, 0);
  color: var(--white);
  border-color: rgba(0, 0, 0, 0);
}
button.chip[bordless='true'],
a.chip[bordless='true'] {
  border: 0;
}
button.chip[type='transparent-gray'],
a.chip[type='transparent-gray'] {
  background-color: rgba(0, 0, 0, 0);
  color: var(--greyDark);
  border-color: rgba(0, 0, 0, 0);
}
button.chip[hover-type='transparent-gray']:hover,
a.chip[hover-type='transparent-gray']:hover {
  background-color: rgba(0, 0, 0, 0);
  color: var(--greyDark);
  border-color: rgba(0, 0, 0, 0);
}
button.chip[type='blue'],
a.chip[type='blue'] {
  background-color: #0084ff;
  color: var(--white);
  border-color: #0084ff;
}
button.chip[hover-type='blue']:hover,
a.chip[hover-type='blue']:hover {
  background-color: #0084ff;
  color: var(--white);
  border-color: #0084ff;
}
button.chip[type='main'],
a.chip[type='main'] {
  background-color: var(--white);
  color: #0084ff;
  border-color: var(--white);
}
button.chip[hover-type='main']:hover,
a.chip[hover-type='main']:hover {
  background-color: var(--white);
  color: #0084ff;
  border-color: var(--white);
}
