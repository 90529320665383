two-column-list a {
  color: var(--grey);
  text-decoration: underline;
}
two-column-list .header {
  font-size: 23px;
  line-height: 1.04;
  text-align: left;
  color: var(--greyDark);
  margin-left: 12px;
  margin-right: 12px;
}
two-column-list .subhead {
  margin-left: 12px;
  margin-top: 12px;
  margin-right: 12px;
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey);
}
two-column-list .section-header {
  margin-left: 12px;
  margin-top: 24px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey);
}
two-column-list .title {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  text-align: left;
  color: var(--black);
}
two-column-list .sub-title {
  font-size: 11px;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey);
  display: flex;
  flex-wrap: wrap;
}
two-column-list .title-wrap {
  white-space: normal;
}
two-column-list .text {
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: var(--grey);
}
two-column-list .title-selected {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: var(--white) !important;
}
two-column-list .sub-title-selected {
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #79bbf6;
}
two-column-list .text-selected {
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #79bbf6;
}
two-column-list .text-selected a {
  color: #79bbf6;
  text-decoration: underline;
}
two-column-list .row {
  cursor: pointer;
  position: relative;
  width: 100%;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 1px 0;
  margin-bottom: 24px;
}
two-column-list .row:hover .title {
  color: var(--blue);
}
two-column-list .row:not(:last-child):not(.row-selected)::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  bottom: -8px;
  left: 0;
  height: 1px;
  background-color: #ececec;
}
two-column-list .row-selected {
  border-radius: 8px;
  background-color: var(--blue);
}
two-column-list .row-left-column {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 16px;
  min-width: 145px;
}
