loader {
  display: inline-block;
  background: transparent url('./images/spinner_b.svg') no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  width: 32px;
  height: 32px;
}
loader[medium] {
  width: 26px;
  height: 26px;
}
loader[xsmall] {
  width: 16px;
  height: 16px;
}
loader[icon-color='white'] {
  background-image: url('./images/spinner_white.svg');
}
loader[icon-color='black-opacity'] {
  background-image: url('./images/spinner_b.svg');
  opacity: 0.5;
}
