.border-rounded,
.border-rounded-12 {
  border-radius: 12px;
}
.border-rounded-right,
.border-rounded-12-right {
  border-radius: 0 12px 12px 0;
}
.border-rounded-left,
.border-rounded-12-left {
  border-radius: 12px 0 0 12px;
}
.border-rounded-8 {
  border-radius: 8px;
}
.border-rounded-8-right {
  border-radius: 0 8px 8px 0;
}
.border-rounded-8-left {
  border-radius: 8px 0 0 8px;
}
