avatar-stack {
  height: 36px;
}
avatar-stack > .inline-avatar-box {
  display: inline-flex;
}
avatar-stack > .inline-avatar-box > *:not(:first-child) {
  margin-left: -20px;
}
avatar-stack[small='true'] {
  height: 16px;
}
avatar-stack[small='true'] > .inline-avatar-box {
  display: inline-flex;
}
avatar-stack[small='true'] > .inline-avatar-box > *:not(:first-child) {
  margin-left: -2px;
}
