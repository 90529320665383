ui-counter {
  display: flex;
  flex-direction: column;
}
ui-counter .count-container {
  display: flex;
  flex-direction: row;
}
ui-counter .count-container > *:not(:first-child) {
  margin-left: 4px;
}
ui-counter .title {
  font-size: 12px;
  color: var(--grey);
}
ui-counter .count {
  display: inline-block;
  font-size: 15px;
  color: var(--black);
}
ui-counter .diff {
  display: inline-block;
  font-size: 15px;
}
ui-counter .diff-green {
  font-size: 15px;
  color: #50c478;
}
ui-counter .diff-red {
  font-size: 15px;
  color: #f06040;
}
