switcher-label .label-tag {
  display: flex;
  align-items: center;
}
switcher-label .label-tag .label-text {
  font-size: 13px;
  color: #767676;
  line-height: 16px;
}
switcher-label .label-tag .label-text-position {
  margin: 0 8px 2px 0;
}
