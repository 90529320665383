@import '../../modern-ui/_common/colors/colors.css';
/*112px - left/right padding (112*2 = 224px)*/
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
.text-ellipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-normal {
  text-transform: none;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-nowrap {
  white-space: nowrap;
}
.text-undeline {
  text-decoration: underline;
}
.popup .text-info {
  color: var(--greyDark);
  font-size: 12px;
}
.text-through {
  text-decoration: line-through;
}
.display-1 {
  text-align: left;
  height: 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  color: var(--black);
}
.body-25 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: var(--black);
}
.body-24 {
  font-size: 18px;
  font-weight: bold;
  color: var(--white);
  line-height: 20px;
}
.body-23 {
  font-size: 12px;
  color: #a8d3f8;
}
.body-22 {
  font-size: 16px;
  line-height: 1.2;
}
.body-21 {
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
  text-align: center;
}
.body-20 {
  font-size: 31px;
  font-weight: bold;
  color: var(--black);
}
.body-19 {
  font-size: 14px;
  line-height: 1.38;
  color: var(--black);
}
.body-18 {
  font-size: 14px;
  line-height: 1.23;
}
.body-17 {
  font-size: 16px;
  text-align: left;
  color: var(--black);
}
.body-16 {
  font-size: 13px;
  color: var(--grey);
}
.body-15 {
  font-size: 18px;
  font-weight: bold;
  color: var(--black);
}
.body-14 {
  opacity: 0.48;
  font-size: 12px;
  text-align: left;
  color: #282828;
}
.heading {
  font-size: 24px;
  line-height: 32px;
  color: var(--greyDark);
  margin-bottom: calc(var(--unit) * 4);
}
.paragraph {
  margin: 4px 0;
}
.body-13 {
  font-size: 23px;
  color: --greyDark;
}
.body-12 {
  font-size: 15px;
  color: var(--grey);
}
.body-11 {
  font-size: 23px;
  font-weight: bold;
  line-height: 1.04;
  color: var(--black);
}
.body-10 {
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: --greyDark;
}
.body-9 {
  font-size: 23px;
  line-height: 1.39;
  color: var(--white);
  letter-spacing: -0.4px;
}
.body-8 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--greyLight30);
  letter-spacing: -0.4px;
}
.body-7 {
  font-size: 15px;
  line-height: 1.07;
  text-align: left;
  color: var(--white);
}
.body-6 {
  font-size: 14px;
  text-align: left;
  color: --greyDark;
}
.body-5 {
  font-size: 14px;
  text-align: left;
  color: var(--black);
}
.body-4 {
  font-size: 16px;
  text-align: left;
  color: #616161;
}
.body-2 {
  font-size: 14px;
  text-align: left;
  color: #40403c;
}
.body-1 {
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: #666666;
}
.body-3 {
  font-size: 15px;
  line-height: 20px;
  color: var(--black);
}
.body-3-semibold {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: var(--black);
}
.blue-text {
  color: var(--blue) !important;
}
.underline-on-hover {
  text-decoration: none;
  cursor: pointer;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.no-underline {
  text-decoration: none;
  cursor: pointer;
}
.underline-no-on-hover {
  text-decoration: underline;
  cursor: pointer;
}
.underline-no-on-hover:hover {
  text-decoration: none;
}
.link {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.link-2 {
  color: #0084ff;
  text-decoration: none;
  cursor: pointer;
}
.link-2:hover {
  text-decoration: underline;
}
.link-3 {
  color: var(--grey);
  text-decoration: none;
  cursor: pointer;
}
.link-3:hover {
  text-decoration: underline;
}
.link-3 {
  color: var(--grey);
  text-decoration: none;
  cursor: pointer;
}
.link-3:hover {
  text-decoration: underline;
}
.link-4 {
  color: --greyDark;
  text-decoration: underline;
  cursor: pointer;
}
.link-4:hover {
  text-decoration: none;
}
.link-5 {
  color: var(--red);
  text-decoration: none;
  cursor: pointer;
}
.link-5:hover {
  text-decoration: underline;
}
.link-6 {
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
}
.link-6:hover {
  text-decoration: none;
}
.icon-link {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
  transition: color 240ms ease 0ms;
}
.icon-link icon {
  opacity: 0.87;
  transition: opacity 240ms ease 0ms;
}
.icon-link:hover {
  color: var(--blueDark10);
}
.icon-link:hover icon {
  opacity: 1;
}
.link-main-color-no-underline {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
}
.full-opacity-on-hover {
  opacity: 0.6;
  cursor: pointer;
}
.full-opacity-on-hover:hover {
  opacity: 1;
}
/**
 * !important
 * @note:I dont know how to share varibaled between less code and js code;
 * @cf-menu-item-height is used as code duplication in dropdown-menu to align popover;
 * if you want to change it keep in sync with dropdown-menu;
 */
cf-menu {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  border-radius: 4px;
  background-color: rgba(255, 254, 254, 0.96);
  padding: 2px 0;
  box-shadow: 0 0 0 1px rgba(20, 20, 20, 0.04), 0 4px 24px 0 rgba(20, 20, 20, 0.08);
  z-index: 200;
  overflow: hidden;
}
cf-menu[max-height='small'] {
  max-height: 101px;
  overflow: auto;
}
cf-menu[max-height='medium'] {
  max-height: 157px;
  overflow: auto;
}
cf-menu-item {
  display: flex;
  flex: 0 0 auto;
  max-height: 28px;
  align-items: center;
  background-color: rgba(255, 254, 254, 0.96);
  white-space: nowrap;
  margin: 2px 0;
  font-size: 15px;
  line-height: 20px;
  color: var(--black);
  cursor: pointer;
  padding-bottom: 2px;
}
cf-menu-item[paddings='true'] {
  padding: 8px 12px;
}
cf-menu-item cf-menu-item-subheader {
  color: #c0c0c0;
  font-size: 11px;
  margin-right: 12px;
  position: relative;
  top: 2px;
}
cf-menu-item[color='main'] cf-menu-item-subheader {
  color: rgba(20, 20, 20, 0.24);
}
cf-menu-item[cf-selectable='true']:hover:not([hoverable='false']) {
  background-color: #f3f3f3;
  color: var(--black);
}
cf-menu-item[cf-selectable='true']:hover:not([hoverable='false']) cf-menu-item-subheader {
  color: #c0c0c0;
}
cf-menu-item[cf-selectable='true'][cf-selected='true'] {
  background-color: #f3f3f3;
  color: var(--black);
}
cf-menu-item[cf-selectable='true'][cf-selected='true'] cf-menu-item-subheader {
  color: #c0c0c0;
}
cf-menu-item[cf-selectable='true'][color='main']:hover:not([hoverable='false']) {
  background-color: var(--blue);
  color: var(--white);
}
cf-menu-item[cf-selectable='true'][color='main']:hover:not([hoverable='false']) cf-menu-item-subheader {
  color: rgba(255, 255, 255, 0.4);
}
cf-menu-item[cf-selectable='true'][color='main'][cf-selected='true'] {
  background-color: var(--blue);
  color: var(--white);
}
cf-menu-item[cf-selectable='true'][color='main'][cf-selected='true'] cf-menu-item-subheader {
  color: rgba(255, 255, 255, 0.4);
}
