popup-datepicker {
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--white);
}
popup-datepicker .input-for-popup-datepicker {
  width: 100%;
  background-color: transparent;
}
popup-datepicker .uib-datepicker-popup {
  display: block;
  position: absolute;
  background-color: var(--white);
  padding: 12px 12px 0;
  z-index: var(--zindex-dropdown);
  border-radius: 12px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
}
popup-datepicker .uib-datepicker-popup li {
  display: block;
}
popup-datepicker .uib-datepicker-popup li .uib-datepicker {
  margin: 0;
}
popup-datepicker .uib-datepicker-popup li .uib-datepicker button[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
popup-datepicker.big .uib-datepicker-popup {
  padding: 12px 24px 0;
}
