/**
 * store standard css properties but in mixins to prefix different browser compatibility
 */
text-field-box {
  display: flex;
  align-items: center;
  padding: 4px;
  height: 36px;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 1px 0 0 rgba(16, 16, 16, 0.04), 0 0 0 1px rgba(16, 16, 16, 0.08);
}
text-field-box[type='active'] {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4), 0 0 0 1px rgba(32, 32, 32, 0.08), 0 1px 0 0 rgba(32, 32, 32, 0.16);
}
text-field-box[type='error'] {
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(32, 32, 32, 0.08), 0 1px 0 0 rgba(32, 32, 32, 0.16);
}
text-field-box[type='transparent'] {
  box-shadow: none;
}
text-field-box[type='brown'] {
  box-shadow: 0 0 0 2px rgba(5, 55, 72, 0.4);
}
text-field-box[type='gray'] {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.13), inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
}
text-field-box[type='borderless'] {
  box-shadow: none;
}
