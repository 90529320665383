button-with-icon-special-case {
  display: flex;
  align-items: center;
}
button-with-icon-special-case:hover {
  cursor: pointer;
}
button-with-icon-special-case:hover * {
  color: var(--white) !important;
  fill: var(--blue) !important;
  border-color: var(--blue) !important;
}
button-with-icon-special-case:hover > cf-button {
  background-color: var(--blue) !important;
}
button-with-icon-special-case > *:not(:first-child) {
  margin-left: 12px;
}
button-with-icon-special-case .icon_width {
  display: flex;
  align-items: center;
  width: 16px;
}
button-with-icon-special-case .icon {
  fill: #aeadad;
}
