ui-picker-view {
  position: relative;
  height: 96px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: flex-start;
}
ui-picker-view .uib-datepicker {
  margin: 0;
}
ui-picker-view .uib-datepicker button[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
ui-picker-view .container_size {
  flex-wrap: nowrap;
  flex-direction: row;
  height: 38px;
}
ui-picker-view .container_color {
  background-color: var(--white);
}
ui-picker-view .position-text-field-box-over-omnibox {
  position: absolute;
  width: calc(100% - 3px);
  pointer-events: none;
  margin: 0 auto;
  top: 29px;
}
ui-picker-view .center_textfield {
  position: relative;
  top: 30px;
}
ui-picker-view .add-vertical-separator {
  position: relative;
}
ui-picker-view .add-vertical-separator::after {
  content: '';
  margin: auto 0;
  background-color: var(--greyLight20);
  height: 66%;
  width: 1px;
  position: relative;
  top: -1px;
}
ui-picker-view .add-vertical-separator:last-of-type::after {
  display: none;
}
ui-picker-view .remove-padding {
  padding: 0;
}
ui-picker-view .flex-1-1 {
  display: inline-flex;
  flex: 1 1 0px;
  transition: flex 50ms ease-in-out;
  min-width: 0px;
}
ui-picker-view .flex-2-1 {
  display: inline-flex;
  flex: 2 1 0px;
  transition: flex 50ms ease-in-out;
  min-width: 0px;
}
ui-picker-view .flex-6-1 {
  display: inline-flex;
  flex: 3 1 0px;
  min-width: 0px;
}
ui-picker-view .horizontal-gradient-mask-bottom {
  pointer-events: none;
  height: 32px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(243, 242, 242, 0)), color-stop(100%, #f3f2f2));
  background: linear-gradient(to bottom, rgba(243, 242, 242, 0) 0%, #f3f2f2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
ui-picker-view .horizontal-gradient-mask-top {
  pointer-events: none;
  height: 32px;
  width: 100%;
  position: absolute;
  top: 0;
  background: #f3f2f2;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #f3f2f2), color-stop(100%, rgba(243, 242, 242, 0)));
  background: linear-gradient(to bottom, #f3f2f2 0%, rgba(243, 242, 242, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
ui-picker-view .vertical-gradient-mask-opaque {
  width: 8px;
  position: absolute;
  height: 96px;
  top: -32px;
  right: 1px;
  background: #f3f2f2;
  pointer-events: none;
}
ui-picker-view .vertical-gradient-mask {
  pointer-events: none;
  width: 16px;
  position: absolute;
  height: 96px;
  top: -32px;
  right: 9px;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(left top, right top, color-stop(0%, #f3f2f2), color-stop(100%, #ffffff));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #f3f2f2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
}
ui-picker-view[type='white'] .horizontal-gradient-mask-bottom {
  pointer-events: none;
  height: 32px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, #ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
ui-picker-view[type='white'] .horizontal-gradient-mask-top {
  pointer-events: none;
  height: 32px;
  width: 100%;
  position: absolute;
  top: 0;
  background: #ffffff;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, rgba(255, 255, 255, 0)));
  background: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
ui-picker-view[type='white'] .vertical-gradient-mask-opaque {
  width: 8px;
  position: absolute;
  height: 96px;
  top: -32px;
  right: 1px;
  background: #ffffff;
  pointer-events: none;
}
ui-picker-view[type='white'] .vertical-gradient-mask {
  pointer-events: none;
  width: 16px;
  position: absolute;
  height: 96px;
  top: -32px;
  right: 9px;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(left top, right top, color-stop(0%, #ffffff), color-stop(100%, #ffffff));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
}
ui-picker-view[type='light-gray'] .horizontal-gradient-mask-bottom {
  pointer-events: none;
  height: 32px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(253, 253, 253, 0)), color-stop(100%, #fdfdfd));
  background: linear-gradient(to bottom, rgba(253, 253, 253, 0) 0%, #fdfdfd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
ui-picker-view[type='light-gray'] .horizontal-gradient-mask-top {
  pointer-events: none;
  height: 32px;
  width: 100%;
  position: absolute;
  top: 0;
  background: #fdfdfd;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, rgba(253, 253, 253, 0)));
  background: linear-gradient(to bottom, #fdfdfd 0%, rgba(253, 253, 253, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
ui-picker-view[type='light-gray'] .vertical-gradient-mask-opaque {
  width: 8px;
  position: absolute;
  height: 96px;
  top: -32px;
  right: 1px;
  background: #fdfdfd;
  pointer-events: none;
}
ui-picker-view[type='light-gray'] .vertical-gradient-mask {
  pointer-events: none;
  width: 16px;
  position: absolute;
  height: 96px;
  top: -32px;
  right: 9px;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(left top, right top, color-stop(0%, #fdfdfd), color-stop(100%, #ffffff));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fdfdfd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
}
ui-picker-view[suggest='hidden'] {
  height: 42px;
  overflow: hidden;
}
ui-picker-view[suggest='hidden'] .horizontal-gradient-mask-top {
  display: none;
}
ui-picker-view[suggest='hidden'] .horizontal-gradient-mask-bottom {
  display: none;
}
ui-picker-view[suggest='hidden'] .center_textfield {
  top: 3px;
}
ui-picker-view[suggest='hidden'] .position-text-field-box-over-omnibox {
  top: 2px;
}
ui-picker-view[suggest='hidden-top'] {
  height: 75px;
  overflow: hidden;
}
ui-picker-view[suggest='hidden-top'] .horizontal-gradient-mask-top {
  display: none;
}
ui-picker-view[suggest='hidden-top'] .center_textfield {
  top: 2px;
}
ui-picker-view[suggest='hidden-top'] .position-text-field-box-over-omnibox {
  top: 1px;
}
ui-picker-view-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 1px;
  min-width: 120px;
}
ui-picker-view-dropdown cf-menu-item {
  height: 32px;
  background-color: transparent;
}
ui-picker-view-dropdown .full-width {
  width: 100%;
}
ui-picker-view-dropdown .disabled-text-color {
  color: var(--grey);
}
ui-picker-view-dropdown .bound_view {
  min-height: 96px;
  height: 96px;
  transform: translate3d(0, -30px, 0);
  overflow: hidden;
}
ui-picker-view-dropdown .no-outline-on-focus {
  outline: none;
}
ui-picker-view-dropdown .animate_transform {
  transition: transform 100ms;
}
ui-picker-view-dropdown .menu-item_like_input_paddings {
  padding-left: 12px;
  padding-right: 12px;
}
ui-picker-view-dropdown .menu-container-padding {
  padding: 4px 0;
  padding-bottom: 2px;
  background-color: var(--white);
}
