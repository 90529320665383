fb-uni-button {
  display: block;
  position: relative;
  height: 38px;
}
fb-uni-button fb-button-box {
  position: absolute;
  z-index: var(--zindex-mask);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.01;
}
fb-uni-button fb-button-box .fb-send-to-messenger {
  transform: scale(5, 5) translate(30%, 30%);
}
fb-uni-button fb-button-box .fb-send-to-messenger iframe {
  position: static;
}
fb-uni-button .button_hide {
  pointer-events: none;
  opacity: 0;
  width: 0;
}
fb-uni-button .button_show {
  pointer-events: auto;
  opacity: 1;
  width: auto;
}
fb-uni-button .button_animate {
  transition: opacity 240ms ease;
}
fb-uni-button .open-messenger-button_layout {
  transform: translateY(-100%);
  display: block;
}
fb-uni-button .loader_position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
