scroll-box {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}
scroll-box[direction='horizontal'] {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
scroll-box[show-scroll='false'] {
  overflow-x: hidden;
  overflow-y: hidden;
}
