/**
  collect all buttons here;
  TODO: on next change redo add-button used in omnibox here; @Artem lets talk about this
 */
@import '../../modern-ui/_common/colors/colors.css';
@import '../../modern-ui/_common/colors/colors.css';
.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
.disabled * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.disabled .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
cf-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 0 26px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: 8px;
  line-height: 16px;
  font-size: 16px;
}
cf-button:active {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-left-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.22);
  transition: box-shadow 20ms ease, border-color 20ms ease;
}
cf-button:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.13);
}
cf-button > icon {
  width: 12px;
  height: 12px;
  margin: 12px;
  padding: 0 2px;
}
cf-button > cf-button-label {
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
}
cf-button[short='true'] {
  line-height: 14px;
  font-size: 14px;
}
cf-button[short='true'] > icon {
  margin: 8px;
}
cf-button[short='true'] > cf-button-label {
  height: 28px;
  line-height: 28px;
  white-space: nowrap;
}
cf-button[type='no-attantion-button'] {
  font-size: 15px;
  border: solid 2px #aeadad;
  color: var(--grey);
  font-weight: bold;
}
cf-button[type='no-attantion-button'] > cf-button-label {
  position: relative;
  top: -3px;
  height: 24px;
}
cf-button icon:nth-child(1) {
  margin-left: 0;
}
cf-button icon:nth-child(2) {
  margin-right: 0;
}
cf-button[narrow] {
  padding: 0 12px;
}
cf-button[middle] {
  padding: 0 16px;
}
cf-button[caps='true'] {
  text-transform: uppercase;
}
cf-button[disabled] {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
cf-button[disabled] * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
cf-button[disabled] .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
cf-button[size='small'] {
  height: 32px;
}
cf-button[shadow='false'] {
  box-shadow: none !important;
}
cf-button[main-action-button='true'] {
  border: none;
  background-color: #f25f38;
  color: var(--white);
}
cf-button[main-action-button='true'][main-action-button-type='main'] {
  background-color: var(--blue);
  color: var(--white);
}
cf-button[main-action-button='true'][main-action-button-type='blue'] {
  background-color: var(--greyLight30);
  color: var(--blue);
}
cf-button[main-action-button='true'][main-action-button-type='gray'] {
  background-color: var(--greyLight30);
  color: var(--black);
}
cf-button[rounded='true'] {
  border-radius: 42px;
}
cf-button .cf-button-shadow {
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.08);
}
cf-button[theme='blue'] {
  background-color: var(--blue);
  color: var(--white);
  border-color: var(--blue);
  font-weight: normal;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.08);
  transition: background-color 240ms ease 0ms;
}
cf-button[theme='blue']:hover {
  background-color: var(--blueDark10);
}
cf-button[theme='gray'] {
  background-color: var(--greyLight20);
  border-color: var(--greyLight20);
  color: var(--black);
  font-weight: normal;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.08);
}
cf-button[theme='white'] {
  background-color: var(--greyLight30);
  color: var(--black);
  border-color: var(--greyLight30);
  font-weight: normal;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.08);
}
cf-button[theme='opacity-white'] {
  background-color: rgba(255, 254, 254, 0.12);
  border: none;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.08);
  color: var(--white);
  transition: background-color 240ms ease 0ms;
}
cf-button[theme='opacity-white']:hover,
cf-button[theme='opacity-white'].hover {
  background-color: rgba(255, 254, 254, 0.24);
}
cf-button[theme='opacity-black'] {
  background-color: rgba(255, 254, 254, 0.08);
  border: none;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.08);
  color: var(--black);
}
cf-button[theme='filled-gray'] {
  background-color: #aeadad;
  border: none;
  height: 24px;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fdfdfd;
}
cf-button[theme='filled-gray']:hover {
  box-shadow: none !important;
}
cf-button[theme='filled-gray'] > cf-button-label {
  height: auto;
  line-height: normal;
  font-weight: bold;
  font-size: 12px;
}
cf-button[theme='dark'] {
  background-color: var(--greyDark);
  margin-right: 6px;
  border: solid 1px rgba(255, 255, 255, 0.24);
  border-radius: 6px;
}
cf-button[theme='dark'] cf-button-label {
  background-color: var(--greyDark);
  color: var(--white);
}
cf-button[theme='dark']:active {
  background-color: var(--greyDark);
}
cf-button[theme='transparent'] {
  background-color: transparent;
  border-color: transparent;
  color: var(--black);
  font-weight: bold;
  transition: color 240ms ease 0ms;
}
cf-button[theme='transparent']:hover {
  box-shadow: none;
  color: var(--blue);
}
cf-button[theme='transparent'][active='true'] {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--blue);
  pointer-events: none;
}
cf-button[theme='blue-border'] {
  background-color: var(--white);
  color: var(--blue);
  border-color: var(--blue);
  font-weight: normal;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.08);
}
cf-button[theme='blue-no-border'] {
  background-color: var(--greyLight30);
  color: var(--blue);
  border-color: var(--greyLight30);
  font-weight: 600;
}
cf-button[theme='blue-no-border'] .button-icon_color {
  stroke: var(--blue);
}
cf-button[theme='blue-no-border']:hover {
  color: var(--black);
}
cf-button[theme='blue-no-border']:hover .button-icon_color {
  stroke: var(--black);
}
icon {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
icon[type='trash'] {
  background-image: url('../../assets2/images/ic_trash_midle.svg');
}
icon[type='arrow-down'] {
  width: 10px;
  height: 10px;
  background-image: url('../../assets2/images/selector.svg');
}
icon[type='copy'] {
  background-image: url('../icon/images/ic_clipboard.svg');
}
icon[type='add'] {
  background-image: url('../../assets2/images/ic_add_small.svg');
  background-size: auto;
}
icon[rounded] {
  border-radius: 50%;
}
icon[type='duplicate'] {
  background-image: url('../icon/images/duplicate.svg');
}
icon[type='cross'] {
  background-image: url('../../assets2/images/ic_close.svg');
}
icon[type='receipt-gray'] {
  background-image: url('../icon/images/receipt-gray.svg');
}
icon[type='receipt-blue'] {
  background-image: url('../icon/images/receipt-blue.svg');
}
icon[type='horizontal-dots'] {
  background-image: url('../icon/images/horizontal_dots.svg');
}
icon[type='cross-white'] {
  background-image: url('../icon/images/ic_cross_white.svg');
}
icon[type='comment-plugin'] {
  background-image: url('../../common/plugins/assets/images/icons/ic_comment.svg');
}
icon[type='blue-email'] {
  background-image: url('../icon/images/blue_email.svg');
}
icon[type='blue-phone'] {
  background-image: url('../icon/images/blue_phone.svg');
}
icon[type='google-sheet'] {
  background-image: url('../icon/images/ic_gsheets_round.svg');
}
icon[type='handover'] {
  background-image: url('../icon/images/ic_handover_round.svg');
}
icon[type='pro'] {
  background-image: url('../icon/images/pro.svg');
}
icon[type='arrow-left'] {
  background-image: url('../icon/images/ic_arr.svg');
}
icon[type='arrow-right'] {
  background-image: url('../icon/images/ic_arr.svg');
  transform: rotate(180deg);
}
icon[type='woman'] {
  background-image: url('../icon/images/woman.png');
  width: 28px;
  height: 22px;
}
icon[type='robot'] {
  background-image: url('../icon/images/robot.png');
  width: 28px;
  height: 22px;
}
icon[type='collision'] {
  background-image: url('../icon/images/collision.png');
  width: 28px;
  height: 22px;
}
icon[type='big-add'] {
  background-image: url('../icon/images/bigest-add.svg');
}
icon[type='big-add-gray'] {
  background-image: url('../icon/images/bigest-add-gray.svg');
}
icon[type='big-add-blue'] {
  background-image: url('../icon/images/bigest-add-blue.svg');
}
icon[type='dotted'] {
  background-image: url('../icon/images/dotted.svg');
}
icon[type='fb-logo'] {
  background-image: url('../icon/images/fb-small.svg');
}
icon[type='link-blue'] {
  background-image: url('../icon/images/link-blue.svg');
}
icon[type='big-big-add'] {
  background-image: url('../icon/images/add_big.svg');
}
icon[type='google-logo'] {
  background-image: url('../icon/images/google_logo.svg');
}
icon[type='open-in-messenger'][icon-color='white'] {
  background-image: url('../icon/images/ic_external_white.svg');
}
icon[type='open-in-messenger'][icon-color='black'] {
  background-image: url('../icon/images/ic_external_black.svg');
}
icon[type='open-in-messenger'][icon-color='blue'] {
  background-image: url('../icon/images/ic_external_blue.svg');
}
icon[type='open-in-messenger'][icon-color='black-opacity'] {
  background-image: url('../icon/images/ic_external_black.svg');
  opacity: 0.5;
}
icon[type='fb-messenger-logo'][icon-color='white'] {
  background-image: url('../icon/images/ic-fb-ms_white.svg');
}
icon[type='fb-messenger-logo'][icon-color='black'] {
  background-image: url('../icon/images/ic-fb-ms_black.svg');
}
icon[type='fb-messenger-logo'][icon-color='black-opacity'] {
  background-image: url('../icon/images/ic-fb-ms_black.svg');
  opacity: 0.5;
}
icon[type='emoji'] {
  width: 28px;
  height: 22px;
}
icon[type='emoji'][emoji-name='person-raising-both-hands-in-celebration'] {
  background-image: url('../icon/images/emoji/person-raising-both-hands-in-celebration.png');
}
icon[type='emoji'][emoji-name='raised-hand'] {
  background-image: url('../icon/images/emoji/raised-hand.png');
}
icon[type='emoji'][emoji-name='flexed-biceps'] {
  background-image: url('../icon/images/emoji/flexed-biceps.png');
}
icon[type='emoji'][emoji-name='waving-hand-sign'] {
  background-image: url('../icon/images/emoji/waving-hand-sign.png');
}
icon[type='emoji'][emoji-name='robot-face'] {
  background-image: url('../icon/images/robot.png');
}
icon[type='inbox'] {
  background-image: url('../icon/images/ic_inbox.svg');
}
icon[type='inbox'][icon-color='light-gray'] {
  background-image: url('../icon/images/ic_inbox_light_gray.svg');
}
icon[type='beta'] {
  background-image: url('../icon/images/beta.svg');
}
icon[inline='true'] {
  display: inline-block;
}
icon[align='center'] {
  vertical-align: -5px;
}
.appear-icon-button-on-hover icon-button {
  opacity: 0;
  transform: scale(0.3);
  transition: transform cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms;
}
.appear-icon-button-on-hover:hover icon-button {
  opacity: 1;
  transform: scale(1);
}
icon-button {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
icon-button > * {
  pointer-events: none;
}
icon-button[size='small'] {
  width: 21px;
  height: 21px;
  padding: 3px;
}
icon-button[size='small'] > icon {
  width: 15px;
  height: 15px;
}
icon-button[size='sub-medium'] {
  width: 24px;
  height: 24px;
  padding: 3px;
}
icon-button[size='sub-medium'] > icon {
  width: 18px;
  height: 18px;
}
icon-button[size='medium'] {
  width: 28px;
  height: 28px;
  padding: 2px;
}
icon-button[size='medium'] > icon {
  width: 24px;
  height: 24px;
}
icon-button[disabled] {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
icon-button[disabled] * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
icon-button[disabled] .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
icon-button[rounded] {
  border-radius: 50%;
  overflow: hidden;
}
icon-button[actionable='true'] > icon {
  opacity: 0.3;
}
icon-button[actionable='true']:hover > icon {
  opacity: 1;
}
icon-button[type='square-rounded'] {
  border-radius: 8px;
  background-color: rgba(20, 20, 20, 0.16);
}
icon-button[type='square-rounded'] > icon {
  width: 16px;
  height: 16px;
}
link-button {
  display: inline;
  text-decoration: none;
  cursor: pointer;
}
link-button:hover {
  text-decoration: underline;
}
link-button[theme='blue'] {
  color: var(--blue);
  font-weight: 600;
}
link-button[theme='blue']:hover {
  text-decoration: none;
  color: var(--black);
}
link-button[theme='black'] {
  color: var(--black);
  font-weight: 600;
}
link-button[theme='black']:hover {
  text-decoration: none;
  color: var(--black);
}
link-button[disabled] {
  pointer-events: none;
  color: var(--grey);
}
