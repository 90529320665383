avatar {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 0px 2px var(--white);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
avatar .avatar-pic_makeup {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
avatar[show-default-avatar] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url('./images/unknown-user.svg');
}
avatar[show-default-avatar='false'] {
  background-image: none;
  background-color: #a5a5a5;
}
avatar[small='true'] {
  width: 16px;
  height: 16px;
  box-shadow: 0px 0px 0px 1px var(--white);
}
avatar[size='big'] {
  width: 40px;
  height: 40px;
}
avatar[size='biggest'] {
  width: 96px;
  height: 96px;
}
avatar[no-border='true'] {
  box-shadow: none;
}
