icon {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
icon[type='trash'] {
  background-image: url('../../assets2/images/ic_trash_midle.svg');
}
icon[type='arrow-down'] {
  width: 10px;
  height: 10px;
  background-image: url('../../assets2/images/selector.svg');
}
icon[type='copy'] {
  background-image: url('./images/ic_clipboard.svg');
}
icon[type='add'] {
  background-image: url('../../assets2/images/ic_add_small.svg');
  background-size: auto;
}
icon[rounded] {
  border-radius: 50%;
}
icon[type='duplicate'] {
  background-image: url('./images/duplicate.svg');
}
icon[type='cross'] {
  background-image: url('../../assets2/images/ic_close.svg');
}
icon[type='receipt-gray'] {
  background-image: url('./images/receipt-gray.svg');
}
icon[type='receipt-blue'] {
  background-image: url('./images/receipt-blue.svg');
}
icon[type='horizontal-dots'] {
  background-image: url('./images/horizontal_dots.svg');
}
icon[type='cross-white'] {
  background-image: url('./images/ic_cross_white.svg');
}
icon[type='comment-plugin'] {
  background-image: url('../../common/plugins/assets/images/icons/ic_comment.svg');
}
icon[type='blue-email'] {
  background-image: url('./images/blue_email.svg');
}
icon[type='blue-phone'] {
  background-image: url('./images/blue_phone.svg');
}
icon[type='google-sheet'] {
  background-image: url('./images/ic_gsheets_round.svg');
}
icon[type='handover'] {
  background-image: url('./images/ic_handover_round.svg');
}
icon[type='pro'] {
  background-image: url('./images/pro.svg');
}
icon[type='arrow-left'] {
  background-image: url('./images/ic_arr.svg');
}
icon[type='arrow-right'] {
  background-image: url('./images/ic_arr.svg');
  transform: rotate(180deg);
}
icon[type='woman'] {
  background-image: url('./images/woman.png');
  width: 28px;
  height: 22px;
}
icon[type='robot'] {
  background-image: url('./images/robot.png');
  width: 28px;
  height: 22px;
}
icon[type='collision'] {
  background-image: url('./images/collision.png');
  width: 28px;
  height: 22px;
}
icon[type='big-add'] {
  background-image: url('./images/bigest-add.svg');
}
icon[type='big-add-gray'] {
  background-image: url('./images/bigest-add-gray.svg');
}
icon[type='big-add-blue'] {
  background-image: url('./images/bigest-add-blue.svg');
}
icon[type='dotted'] {
  background-image: url('./images/dotted.svg');
}
icon[type='fb-logo'] {
  background-image: url('./images/fb-small.svg');
}
icon[type='link-blue'] {
  background-image: url('./images/link-blue.svg');
}
icon[type='big-big-add'] {
  background-image: url('./images/add_big.svg');
}
icon[type='google-logo'] {
  background-image: url('./images/google_logo.svg');
}
icon[type='open-in-messenger'][icon-color='white'] {
  background-image: url('./images/ic_external_white.svg');
}
icon[type='open-in-messenger'][icon-color='black'] {
  background-image: url('./images/ic_external_black.svg');
}
icon[type='open-in-messenger'][icon-color='blue'] {
  background-image: url('./images/ic_external_blue.svg');
}
icon[type='open-in-messenger'][icon-color='black-opacity'] {
  background-image: url('./images/ic_external_black.svg');
  opacity: 0.5;
}
icon[type='fb-messenger-logo'][icon-color='white'] {
  background-image: url('./images/ic-fb-ms_white.svg');
}
icon[type='fb-messenger-logo'][icon-color='black'] {
  background-image: url('./images/ic-fb-ms_black.svg');
}
icon[type='fb-messenger-logo'][icon-color='black-opacity'] {
  background-image: url('./images/ic-fb-ms_black.svg');
  opacity: 0.5;
}
icon[type='emoji'] {
  width: 28px;
  height: 22px;
}
icon[type='emoji'][emoji-name='person-raising-both-hands-in-celebration'] {
  background-image: url('./images/emoji/person-raising-both-hands-in-celebration.png');
}
icon[type='emoji'][emoji-name='raised-hand'] {
  background-image: url('./images/emoji/raised-hand.png');
}
icon[type='emoji'][emoji-name='flexed-biceps'] {
  background-image: url('./images/emoji/flexed-biceps.png');
}
icon[type='emoji'][emoji-name='waving-hand-sign'] {
  background-image: url('./images/emoji/waving-hand-sign.png');
}
icon[type='emoji'][emoji-name='robot-face'] {
  background-image: url('./images/robot.png');
}
icon[type='inbox'] {
  background-image: url('./images/ic_inbox.svg');
}
icon[type='inbox'][icon-color='light-gray'] {
  background-image: url('./images/ic_inbox_light_gray.svg');
}
icon[type='beta'] {
  background-image: url('./images/beta.svg');
}
icon[inline='true'] {
  display: inline-block;
}
icon[align='center'] {
  vertical-align: -5px;
}
