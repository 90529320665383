@import '../../modern-ui/_common/colors/colors.css';
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
cf-tile-item {
  width: calc(20% - 16px);
  height: 300px;
  border-radius: 8px;
  margin: 8px;
  min-width: 200px;
  max-width: 280px;
  position: relative;
  transform: scale(1);
}
cf-tile-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  z-index: var(--zindex-sub-content-background);
  transition: transform 240ms ease 100ms;
}
cf-tile-item:hover::before {
  transform: scale(1.025);
}
cf-tile-item[loader='true'] {
  background-color: var(--white);
  pointer-events: none;
  position: relative;
  animation: flickerAnimation 1000ms infinite;
}
cf-tile-item[loader='true']::before {
  content: '';
  position: absolute;
  width: 42px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--greyLight20);
  top: 192px;
  left: 17px;
}
cf-tile-item[loader='true']::after {
  content: '';
  position: absolute;
  width: 42px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--greyLight20);
  top: 192px;
  left: 64px;
}
