@import '../../modern-ui/_common/colors/colors.css';
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
.cf-common-tile-image-container {
  height: 64px;
  width: 64px;
  border-radius: 8px;
  background: no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
cf-common-tile {
  display: block;
  width: calc(20% - 16px);
  height: 300px;
  border-radius: 8px;
  min-width: 200px;
  max-width: 280px;
  position: relative;
  background-color: var(--white);
}
cf-common-tile .counters {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
}
cf-common-tile ui-counter {
  width: 50%;
}
cf-common-tile .resize-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  transition: transform 240ms ease 100ms;
}
cf-common-tile .content-container {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  left: 0;
  top: 0;
}
cf-common-tile:hover .resize-container {
  transform: scale(1.025);
}
cf-common-tile[theme='blue'] {
  background-color: var(--blue);
}
cf-common-tile[theme='blue'] .text-container {
  color: var(--white);
}
cf-common-tile[theme='blue'] .sub-text-container {
  opacity: 0.64;
  color: var(--greyLight30);
}
cf-common-tile[text-centered='true'] {
  text-align: center;
}
cf-common-tile[text-centered='true'] .text-container {
  white-space: pre-line;
}
cf-common-tile .bot-notification-tag {
  position: absolute;
  left: 12px;
  top: 12px;
}
cf-common-tile .avatar-stack_spacing {
  position: absolute;
  top: 16px;
  left: 24px;
}
cf-common-tile .icon-menu {
  position: absolute;
  top: 8px;
  right: 16px;
}
cf-common-tile .image-container {
  height: 138px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}
cf-common-tile .text-container {
  height: 60px;
  align-items: flex-end;
  display: flex;
  padding: 0 16px;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 18px;
  font-weight: bold;
  color: var(--black);
}
cf-common-tile .text-container > * {
  width: 100%;
}
cf-common-tile .sub-text-container {
  height: 26px;
  align-items: flex-start;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.32);
  display: flex;
  padding: 0 16px;
  flex-direction: row;
  flex-wrap: nowrap;
}
cf-common-tile .sub-text-container > * {
  width: 100%;
}
cf-common-tile .button-container {
  display: flex;
  margin-top: 12px;
  justify-content: center;
}
cf-common-tile[loader='true'] {
  background-color: #ecebeb;
  pointer-events: none;
  position: relative;
  animation: flickerAnimation 1000ms infinite;
}
cf-common-tile[loader='true'] .loader_container_1 {
  position: absolute;
  width: 42px;
  height: 8px;
  border-radius: 2px;
  background-color: #f9f8f8;
  top: 192px;
  left: 17px;
}
cf-common-tile[loader='true'] .loader_container_2 {
  position: absolute;
  width: 42px;
  height: 8px;
  border-radius: 2px;
  background-color: #f9f8f8;
  top: 192px;
  left: 64px;
}
